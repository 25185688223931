<template>
  <tr>
    <td>{{ buddy.firstName }} {{ buddy.lastName }}</td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "telephone-number-buddy-table-row",
  props: {
    buddy: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"])
  }
};
</script>
