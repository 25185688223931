<template>
  <table-overview
    title="Telephone Numbers"
    store="telephoneNumbers"
    :headers="[
      { text: 'Number', value: 'number' },
      {
        text: 'Actions',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'telephone_numbers_view'
      }
    ]"
    force-initial-sort
  >
    <telephone-number-table-row
      slot="table-row"
      slot-scope="{ item }"
      :telephone-number-id="item"
    />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import telephoneNumbersModule from "@/store/modules/telephone-numbers";
import { mapOverviewLifecycleMethods } from "../helpers";
import TelephoneNumberTableRow from "@/components/telephone-number/TelephoneNumberTableRow";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "telephoneNumbers",
  telephoneNumbersModule
);

export default {
  name: "telephone-numbers-view",
  components: {
    TelephoneNumberTableRow,
    TableOverview
  },
  data: () => ({}),
  ...overviewLifecycleMethods
};
</script>
