<template>
  <v-card color="white">
    <crud-header
      :title="number"
      type="Telephone Number"
      store="telephoneNumber"
      @save-success="
        ({ id }) =>
          $router.push({
            name: 'telephone-numbers.show',
            params: { telephoneNumberId: id }
          })
      "
    />
    <telephone-number />
  </v-card>
</template>

<script type="text/babel">
import telephoneNumberModule from "@/store/modules/telephone-number";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapDetailViewLifecycleMethods } from "../helpers";
import TelephoneNumber from "../../components/telephone-number/TelephoneNumber";

const { mapGetters } = createNamespacedHelpers("telephoneNumber");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "telephoneNumber",
  telephoneNumberModule
);

export default {
  name: "telephone-number-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    TelephoneNumber,
    CrudHeader
  },
  computed: {
    ...mapGetters(["number", "id"])
  },
  ...detailViewLifecycleMethods
};
</script>
