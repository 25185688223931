<template>
  <v-container fluid>
    <v-text-field
      v-model="number"
      label="number"
      data-vv-name="number"
      readonly
    />
    <v-data-table
      title="Buddies"
      :headers="[{ text: 'Name', value: 'first_name' }]"
      :items="buddies"
    >
      <telephone-number-buddy-table-row
        slot="items"
        slot-scope="{ item }"
        :buddy="item"
      />
    </v-data-table>
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import TelephoneNumberBuddyTableRow from "./TelephoneNumberBuddyTableRow";

const { mapComputed } = createNamespacedHelpers("telephoneNumber");

export default {
  name: "telephone-number",
  components: { TelephoneNumberBuddyTableRow },
  inject: ["$validator"],
  data: () => ({
    locale: "nl_be",
    editorOption: {
      theme: "snow",
      modules: {
        markdownShortcuts: {},
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],

          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],

          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],

          ["clean"],
          ["image"],
          ["link"]
        ]
      }
    }
  }),
  computed: {
    ...mapComputed(["telephoneNumber", "number"]),
    buddies: function() {
      return this.telephoneNumber.buddies.map(
        buddyId => this.$store.state.telephoneNumber.buddy[buddyId]
      );
    }
  }
};
</script>
